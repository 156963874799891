import React, { useState } from 'react';
import { Row, Col, Form, Button, Modal } from 'react-bootstrap';
import { trackEvent } from './trackGA';
import SizeChart from './SizeChart';

// ProductOptions Component
const ProductOptions = ({ selectedColor, setSelectedColor, selectedSize, setSelectedSize }) => {
    const [showSizeChart, setShowSizeChart] = useState(false);

    const handleColorChange = (e) => {
        const color = e.target.value;
        setSelectedColor(color);
        trackEvent('Product', 'Color Selected', color);
    };

    const handleSizeChange = (e) => {
        const size = e.target.value;
        setSelectedSize(size);
        trackEvent('Product', 'Select Size', size);
    };

    return (
        <Row className="g-3 align-items-center">
            <Col xs="auto">
                {/* Color Selection */}
                <Form.Select
                    className="form-select color-select w-auto"
                    value={selectedColor}
                    onChange={handleColorChange}
                >
                    <option value="" disabled>Select Color</option>
                    <option value="white">White</option>
                    <option value="black">Black</option>
                </Form.Select>
            </Col>
            <Col xs="auto">
                {/* Size Selection */}
                <Form.Select
                    className="form-select size-select w-auto"
                    value={selectedSize}
                    onChange={handleSizeChange}
                >
                    <option value="" disabled>Select Size</option>
                    <option value="6-12mo">6-12mo</option>
                    <option value="12-18mo">12-18mo</option>
                    <option value="18-24mo">18-24mo</option>
                    <option value="2T">2T</option>
                    <option value="3T">3T</option>
                    <option value="4T">4T</option>
                    <option value="5T">5T</option>
                    <option value="youth-s">Youth S</option>
                    <option value="youth-m">Youth M</option>
                    <option value="youth-l">Youth L</option>
                    <option value="youth-xl">Youth XL</option>
                    <option value="adult-xs">Adult XS</option>
                    <option value="adult-s">Adult S</option>
                    <option value="adult-m">Adult M</option>
                    <option value="adult-l">Adult L</option>
                    <option value="adult-xl">Adult XL</option>
                    <option value="adult-2xl">Adult 2XL</option>
                    <option value="adult-3xl">Adult 3XL</option>
                </Form.Select>
            </Col>
            <Col xs="auto">
                <Button 
                    variant="link" 
                    size="sm" 
                    onClick={() => setShowSizeChart(true)}
                    className="text-muted"
                >
                    Size Chart
                </Button>

                <Modal size="lg" show={showSizeChart} onHide={() => setShowSizeChart(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Size Chart</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <SizeChart />
                    </Modal.Body>
                </Modal>
            </Col>
        </Row>
    );
};

export default ProductOptions;
