import ReactGA from 'react-ga4';

function trackEvent(category, action, label) {
  console.log(`GA Event: ${category} - ${action} - ${label}`);
  ReactGA.event({
    category,
    action,
    label,
  });

  // Facebook Pixel tracking
  if (window.fbq) {
    console.log('Facebook Pixel is available, sending event');
    window.fbq('trackCustom', action, {
      category: category,
      label: label
    });
  }
}

function trackPageview(page, title) {
  console.log(`GA Pageview: ${page} - ${title}`);
  ReactGA.send({ hitType: "pageview", page, title });

  // Facebook Pixel pageview tracking
  if (window.fbq) {
    console.log('Facebook Pixel is available, sending pageview');
    window.fbq('track', 'PageView');
  }
}

export { trackEvent, trackPageview };
