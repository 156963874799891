import React from 'react';
import { Container, Alert, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const CancelPage = () => {
  return (
    <Container className="py-5 text-center">
      <Alert variant="warning">
        <Alert.Heading>Order Cancelled</Alert.Heading>
        <p>
          Your order has been cancelled. No charges have been made to your account.
        </p>
      </Alert>
      <p>
        If you encountered any issues,
        please send an email to <a href="mailto:support@ai-paint-tee.com">support@ai-paint-tee.com</a>.
      </p>
      <Link to="/">
        <Button variant="primary">Return to Home Page</Button>
      </Link>
    </Container>
  );
};

export default CancelPage;
