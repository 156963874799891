import React, { useState, useEffect } from "react";

import Alert from "react-bootstrap/Alert";

function PageAlert({ msg, setAlertMessage }) {
  console.log("PageAlert: msg", msg);

  useEffect(() => {
    if (msg) {
      const timer = setTimeout(() => {
        setAlertMessage('');
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [msg, setAlertMessage]);

  return (
    <>
      {msg ? (
        <Alert
          variant="danger"
          className="fixed-top alert-container"
          style={{ width: "auto", zIndex: 1050, margin: "0 auto" }}
          dismissible
          onClose={() => setAlertMessage('')}
        >
          <p className="text-center fw-bold">{msg}</p>
        </Alert>
      ) : null}
    </>
  );
}

export default PageAlert;