import React, { useState } from 'react';
import { Container, Row, Col, Form, ListGroup, Button, ProgressBar } from 'react-bootstrap';
import './ImageMaker.css';  // Import the custom CSS file
import { trackEvent } from './trackGA';

import ProductOptions from './ProductOptions';  // Import the ProductOptions component
import Designer from './Designer';  // Import the Designer component
import getCookie from './GetCookie';



const ImageMaker = ({ addToCart, setAlertMessage }) => {
    const [text, setText] = useState('');
    const [progress, setProgress] = useState(0); // State to control progress bar value
    const [generatedImage, setGeneratedImage] = useState(''); // State to store the generated image
    const [isGenerating, setIsGenerating] = useState(false); // State to control button disabling
    const [imageId, setImageId] = useState(null); // State to store the unique image ID
    const [selectedSize, setSelectedSize] = useState('');
    const [selectedColor, setSelectedColor] = useState('');

    const styles = ['Sarcastic', 'Funny', 'Humorous', 'Minimalist', 'Cartoon', 'Cute', 'Vintage', 'Humorous and Cartoon'];
    const targets = [
        "Lazy Husband",
        "Mean Wife",
        "Boyfriend who doesn't listen",
        "Girlfriend who loves to shop",
        "Dad who loves to grill",
        "Mom who loves to bake",
        "Son who loves to play video games",
        "Daughter who loves to party",
        "Brother",
        "Sister",
        "Friend",
        "Christmas Party",
        "a female piano teacher teaching a boy to appreciate her work",
    ];

    const [designStyle, setDesignStyle] = useState(styles[0]);
    const [target, setTarget] = useState(targets[0]);
    const [customStyle, setCustomStyle] = useState('');
    const [customTarget, setCustomTarget] = useState('');
    const [isCustomStyleActive, setIsCustomStyleActive] = useState(false);
    const [isCustomTargetActive, setIsCustomTargetActive] = useState(false);

    const price = '14.99'; // Regular variable for price

    const simulateProgress = () => {
        let intervalId;
        intervalId = setInterval(() => {
            setProgress((prevProgress) => {
                if (prevProgress >= 100) {
                    clearInterval(intervalId); // Stop when progress reaches 100%
                    return 100;
                }
                return prevProgress + 1; // Increment progress
            });
        }, 400); // Increment every 400ms for smooth progress
        return intervalId;
    };

    const generateImage = async (style, target) => {
        const csrftoken = getCookie('csrftoken');
        const response = await fetch('/generate-image/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken,
            },
            body: JSON.stringify({ style, target }),
        });

        if (!response.ok) {
            throw new Error('Failed to generate image.');
        }

        return await response.json();
    };

    const handlePaintButtonClick = async () => {
        if (!designStyle || !target) {
            return;
        }

        setProgress(0); // Reset progress
        setGeneratedImage(''); // Clear any previous generated image
        setIsGenerating(true); // Disable the button while generating the image
        setImageId(null); // Reset the image ID

        trackEvent('Product', 'Generate Image', `${designStyle} - ${target}`);

        // Simulate progress
        let interval = simulateProgress();

        // Send POST request to the Django backend to generate the image
        try {
            const data = await generateImage(designStyle, target);
            setGeneratedImage(`data:image/png;base64,${data.image_base64}`); // Set the generated image
            setImageId(data.image_id); // Set the unique image ID
        } catch (error) {
            console.error('Error generating image:', error);
        } finally {
            clearInterval(interval);
            setIsGenerating(false); // Re-enable the button after image generation is complete
        }
    };

    const handleAddToCart = () => {
        trackEvent('Product', 'Add to Cart', imageId);
        if (imageId && selectedSize && selectedColor) {
            const customProduct = {
                productId: imageId,
                productImage: generatedImage,
                productTitle: "Custom AI-Generated T-Shirt",
                productDescription: text,
                productPrice: price // Use the price variable
            };
            addToCart(customProduct, selectedSize, selectedColor);
        } else {
            if (!selectedSize) {
                setAlertMessage('Please select a size.');
            } else if (!selectedColor) {
                setAlertMessage('Please select a color.');
            } else if (!generatedImage) {
                setAlertMessage('Please select a design style and target, then click "AI Paint Tee" button.');
            }
        }
    };

    return (
        <>
            <Container className="col-lg-10 mt-4">
                <Row>
                    <Col xl={6}>
                        <Designer
                            styles={styles}
                            targets={targets}
                            designStyle={designStyle}
                            setDesignStyle={setDesignStyle}
                            target={target}
                            setTarget={setTarget}
                            customStyle={customStyle}
                            setCustomStyle={setCustomStyle}
                            customTarget={customTarget}
                            setCustomTarget={setCustomTarget}
                            isCustomStyleActive={isCustomStyleActive}
                            setIsCustomStyleActive={setIsCustomStyleActive}
                            isCustomTargetActive={isCustomTargetActive}
                            setIsCustomTargetActive={setIsCustomTargetActive}
                        />
                    </Col>
                    <Col xl={6}>
                        <Button
                            className="rainbow-button border-0"
                            disabled={!designStyle || !target || isGenerating}  // Disable the button when designStyle or target is empty, or while generating
                            onClick={handlePaintButtonClick}
                        >
                            {isGenerating ? 'Painting...' : 'AI Paint Tee'}
                        </Button>

                        {/* Progress Bar */}
                        {isGenerating && !generatedImage && (
                            <Row className="mt-3">
                                <Col>
                                    <ProgressBar
                                        striped
                                        animated
                                        now={progress}
                                        variant="info"
                                    />
                                </Col>
                            </Row>
                        )}

                        <Row className="mt-3 position-relative">
                            <Col>
                                {/* Background image */}
                                <div className="image-container">
                                    <img
                                        src="/static/t-shirt-background.png"
                                        alt="T-shirt Background"
                                        className="img-fluid rounded-3 shadow"
                                    />
                                    {/* Overlay image centered on top */}
                                    {generatedImage && (
                                        <img
                                            src={generatedImage}
                                            alt="Generated Design"
                                            className="overlay-image rounded"
                                        />
                                    )}
                                </div>
                            </Col>
                        </Row>
                        {(
                            <>
                                <div className="mt-3">
                                    <ProductOptions
                                        selectedColor={selectedColor}
                                        setSelectedColor={setSelectedColor}
                                        selectedSize={selectedSize}
                                        setSelectedSize={setSelectedSize}
                                    />
                                </div>
                                <Row className="mt-3">
                                    <Col>
                                        <Button
                                            variant="primary"
                                            className="w-100"
                                            onClick={handleAddToCart}
                                        >
                                            Add to Cart (${price})
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </Col>
                </Row>

            </Container>
        </>
    );
};

export default ImageMaker;