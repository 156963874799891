import React from 'react';

const SizeChart = () => {
    return (
        <div className="toggle-block" id="product-measure">
            <h3 className="pf-h3 pf-mt-24 pf-mb-16">Product measurements</h3>
            <div className="pf-ui-body pf-text-muted pf-mb-32">
                <p dir="ltr">
                    Measurements are provided by our suppliers. Product measurements may vary
                    by up to 2" (5 cm).
                </p>
                <p dir="ltr">
                    Pro tip! Measure one of your products at home and compare with the
                    measurements you see in this guide.
                </p>
            </div>
            <div className="pf-bg-lighter pf-px-16 pf-px-md-24 pf-py-24">
                <div className="row">
                    <div className="text-center col-md-5">
                        <img
                            className="img-responsive pf-m-auto"
                            src="https://files.cdn.printful.com/upload/product-measure/e6/e6ab5ac32761974387a1350fe576666e_l?v=1652962719"
                            alt="product-measurements"
                        />
                    </div>
                    <div className="size-guide__image-description col-md-7 pf-mt-24 pf-mt-md-0 pf-pl-md-8">
                        <h6 dir="ltr">A Length</h6>
                        <p dir="ltr">
                            Place the end of the tape beside the collar at the top of the tee
                            (Highest Point Shoulder). Pull the tape measure to the bottom of the
                            shirt.
                        </p>
                        <h6 dir="ltr">B Width</h6>
                        <p dir="ltr">
                            Place the end of the tape at the seam under the sleeve and pull the
                            tape measure across the shirt to the seam under the opposite sleeve.
                        </p>
                    </div>
                </div>
            </div>
            <div className="collapse-block ">
                <div className="collapse-panel pf-mb-24">
                    <div className="collapse-heading basic-domestic">
                        <h3
                            className="pf-h3 pf-keydown-click"
                            role="button"
                            tabIndex={0}
                        >
                            Size chart
                        </h3>
                    </div>
                    <div>
                        <div className="pf-overflow-x-auto">
                            <table>
                                <thead>
                                    <tr>
                                        <th>SIZE LABEL---</th>
                                        <th>LENGTH---</th>
                                        <th>WIDTH</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>6-12m</td>
                                        <td>12 1/4</td>
                                        <td>16 - 22</td>
                                    </tr>
                                    <tr>
                                        <td>12-18m</td>
                                        <td>13 1/4</td>
                                        <td>22 - 27</td>
                                    </tr>
                                    <tr>
                                        <td>18-24m</td>
                                        <td>14 1/4</td>
                                        <td>27 - 30</td>
                                    </tr>
                                    <tr>
                                        <td>2T</td>
                                        <td>15 ½</td>
                                        <td>12</td>
                                    </tr>
                                    <tr>
                                        <td>3T</td>
                                        <td>16 ½</td>
                                        <td>13</td>
                                    </tr>
                                    <tr>
                                        <td>4T</td>
                                        <td>17 ½</td>
                                        <td>14</td>
                                    </tr>
                                    <tr>
                                        <td>5T</td>
                                        <td>18 ½</td>
                                        <td>15</td>
                                    </tr>
                                    <tr>
                                        <td>Youth XS</td>
                                        <td>20 ½</td>
                                        <td>16</td>
                                    </tr>
                                    <tr>
                                        <td>Youth S</td>
                                        <td>22</td>
                                        <td>17</td>
                                    </tr>
                                    <tr>
                                        <td>Youth M</td>
                                        <td>23 ½</td>
                                        <td>18</td>
                                    </tr>
                                    <tr>
                                        <td>Youth L</td>
                                        <td>25</td>
                                        <td>19</td>
                                    </tr>
                                    <tr>
                                        <td>Youth XL</td>
                                        <td>26 ½</td>
                                        <td>20</td>
                                    </tr>
                                    <tr>
                                        <td>XS</td>
                                        <td>27</td>
                                        <td>16 ½</td>
                                    </tr>
                                    <tr>
                                        <td>S</td>
                                        <td>28</td>
                                        <td>18</td>
                                    </tr>
                                    <tr>
                                        <td>M</td>
                                        <td>29</td>
                                        <td>20</td>
                                    </tr>
                                    <tr>
                                        <td>L</td>
                                        <td>30</td>
                                        <td>22</td>
                                    </tr>
                                    <tr>
                                        <td>XL</td>
                                        <td>31</td>
                                        <td>24</td>
                                    </tr>
                                    <tr>
                                        <td>2XL</td>
                                        <td>32</td>
                                        <td>26</td>
                                    </tr>
                                    <tr>
                                        <td>3XL</td>
                                        <td>33</td>
                                        <td>28</td>
                                    </tr>
                                    <tr>
                                        <td>4XL</td>
                                        <td>34</td>
                                        <td>30</td>
                                    </tr>
                                    <tr>
                                        <td>5XL</td>
                                        <td>35</td>
                                        <td>32</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <p>
                            Product measurements may vary by up to 2" (5 cm).
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SizeChart;
